<template>
  <div>
    <div class="container">
      <div class="influencerDetail" v-if="influencer.avatar">
        <div class="influencerDetail__left">
          <div class="influencerDetail__left--image onDesktop">
            <img
              v-lazy="`${$config.IMG_HOST}/450x600/${influencer.avatar}`"
              alt=""
              class="img-fluid"
            />
          </div>
          <!-- hide on Desktop -->

          <div class="onMobile">
            <ul>
              <li class="influencerDetail__left--icon">
                <img
                  v-lazy="`${$config.IMG_HOST}/100x100/${influencer.avatar}`"
                  alt=""
                  class="img-fluid"
                />
              </li>
              <li>
                <h1>{{ influencer.name }}</h1>
                <span
                  class="influencerDetail__right--subTitle"
                  v-if="influencer.location"
                  >{{ influencer.location.state }}</span
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="influencerDetail__right">
          <!-- hide on Mobile -->
          <div class="onDesktop">
            <h1>{{ influencer.name }}</h1>
            <span
              v-if="influencer.location"
              class="influencerDetail__right--subTitle"
              >{{ influencer.location.state }}</span
            >
          </div>
          <media-accounts
            :accounts="influencer.accounts"
            v-if="influencer.accounts"
          />
          <ul class="influencerDetail__right--hashtags">
            <li v-for="(tag, i) in influencer.tags" :key="i + 1">
              <a href="#">#{{ tag.name }}.</a>
            </li>
          </ul>
          <p>
            {{ influencer.description }}
          </p>
          <div class="influencerDetail__right--btnWrapper">
            <h2>
              <span v-if="influencer.price"
                >{{$t('Price')}}: {{ formatPrice(influencer.price) }} RON</span
              >
            </h2>
            <div class="inline-btns onDesktop">
              <a href="#" class="btn btn-primary" v-b-modal.introduction>{{$t('Buy')}}</a>
              <a href="#" class="btn btn-primary" v-show="showMessageButton">{{$t('Message')}}</a>
            </div>
            <div class="inline-btns onMobile">
              <a href="#" class="btn btn-primary">{{$t('Buy Offer')}}</a>
            </div>
          </div>
          <h2 class="onMobile">{{$t('Recent Campaigns')}}</h2>
          <div v-for="(campaign, i) in influencerCampaigns" :key="i + 1">
            <div
              class="d-flex align-items-center mt-3"
              v-if="campaign.subscription"
            >
              <div class="influencerDetail__right--icon">
                <img
                  :src="`${$config.IMG_HOST}/50x50/${campaign.logo}`"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <span>{{ numberToDate(campaign.subscription.timestamp) }}</span>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <influencer-detail-gallery
                  v-for="img in campaign.subscription.images"
                  :key="img.id"
                  :image="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Introduction modal -->
    <b-modal ref="introduction" id="introduction" centered>
      <div class="title">{{$t('Instruction')}}</div>
      <p class="subTitle">
        {{$t('What do you want the local Influencers to include in their posts?')}}
      </p>
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('@Our lnstagram')"
          />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('#Our hashtag')" />
        </div>
        <button type="button" class="btn btn-default full">
          Add images or videos
        </button>
        <div class="form-group">
          <label class="control-label"
            >{{$t('Instructions and campaign details for influencers')}}:
          </label>
          <textarea
            class="form-control"
            placeholder="Ex: Iwant you to talk about FameUp"
          ></textarea>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="hideModal"
          v-b-modal.paymentMethod
        >
          {{$t('continue')}}
        </button>
      </form>
    </b-modal>
    <!-- modal End-->

    <!-- Payment Method modal -->
    <b-modal ref="paymentMethod" id="paymentMethod" centered>
      <div class="title">{{$t('Payment Method')}}</div>
      <p class="subTitle">{{$t('Card Details')}}</p>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('Card Number')" />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('Expiry Date')" />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" placeholder="CVV" />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('First name')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input type="text" class="form-control" :placeholder="$t('Last name')" />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          v-b-modal.invoicingDetail
          @click="hideModal2"
        >
          {{$t('Conﬁrm Payment')}}
        </button>
      </form>
    </b-modal>
    <!-- modal End-->

    <!-- Invocing Detail modal -->
    <b-modal ref="invoicingDetail" id="invoicingDetail" centered>
      <div class="title">{{$t('Add invoicing details')}}</div>
      <ul class="checkbox list-unstyled">
        <li>
          <label>
            <input type="checkbox" name="Individual" />
            <span>{{$t('Individual')}}?</span>
          </label>
        </li>
      </ul>
      <form>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('Company name')" />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('VAT number') " />
        </div>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            :placeholder="$t('Trade registration number')"
          />
        </div>
        <div class="form-group">
          <input type="email" class="form-control" :placeholder="$t('Email') " />
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input type="text" class="form-control" :placeholder="$t('Country')" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input type="text" class="form-control" :placeholder="$t('Locality')" />
            </div>
          </div>
        </div>
        <div class="form-group mt-0">
          <input type="text" class="form-control" :placeholder="$t('Address')  " />
        </div>
        <div class="form-group">
          <input type="text" class="form-control" :placeholder="$t('Post code')  " />
        </div>
        <p class="subTitle legal">{{$t('Legal representative of the company')}}</p>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input
                type="text"
                class="form-control"
                :placeholder="$t('First name')"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mt-0">
              <input type="text" class="form-control" placeholder="$t('Last name')" />
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary" @click="hideModal3">
          Save
        </button>
      </form>
    </b-modal>
    <!-- modal End-->
  </div>
</template>

<script>
import {
  INFLUENSER_DETAIL,
  INFLUENSER__CAMPAIGNS,
} from "@/graphql/brand/query";

export default {
  data() {
    return {
      id: 1,
      limit: 4,
      influencer: [],
      influencerCampaigns: [],
      showMessageButton: false,
    };
  },
  components: {
    MediaAccounts: () =>
      import(
        /* webpackChunkName: "socialMedia" */ "@/components/brand/MediaAccounts.vue"
      ),
    InfluencerDetailGallery: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/brand/influencers/InfluencerDetailGallery.vue"
      ),
  },
  created() {
    this.id = parseInt(this.$route.params.id);
  },
  apollo: {
    influencer: {
      query: INFLUENSER_DETAIL,
      variables() {
        return {
          id: parseInt(this.$route.params.id),
        };
      },
      error(error) {
        const errorCode = this.getErrorCode(error);
        if (errorCode == "USER_NOT_FOUND") {
          // this.$router.push("/brand/404");
          this.$router.push(this.$i18nRoute({name: "brand-404"}));
        } else {
          this.handleError(error);
        }
      },
    },
    influencerCampaigns: {
      query: INFLUENSER__CAMPAIGNS,
      variables() {
        return {
          id: 1,
          limit: this.limit,
        };
      },
    },
  },
  methods: {
    hideModal() {
      this.$refs["introduction"].hide();
    },
    hideModal2() {
      this.$refs["paymentMethod"].hide();
    },
    hideModal3() {
      this.$refs["invoicingDetail"].hide();
    },
  },
  mounted() {
    let newdate = new Date(1546108 * 1000).toTimeString;
  },
};
</script>

<style lang="scss" scoped>
.influencerDetail {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  &__left {
    flex: 0 0 450px;
    max-width: 450px;
    margin-right: rem(32px);
    @media screen and (max-width: 1199px) {
      flex: 0 0 265px;
      max-width: 265px;
    }
    @media screen and (max-width: 991px) {
      flex: 0 0 215px;
      max-width: 215px;
    }
    @media screen and (max-width: 767px) {
      // margin: auto;
      max-width: 100%;
      flex: 100%;
    }
    &--image {
      border-radius: 40px;
      overflow: hidden;
      @include flex(center, center);
    }
    &--icon {
      width: 100px;
      min-width: 100px;
      height: 100px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: rem(18px);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__right {
    flex: 1;
    margin-top: rem(45px);
    h1 {
      margin-bottom: rem(6px);
      font-weight: 600;
    }
    &--subTitle {
      display: block;
      font-size: rem(18px) !important;
      font-weight: 500 !important;
      color: var(--textPrimary) !important;
      font-family: $secondary-font;
      margin-bottom: rem(18px);
    }
    &--socialMedia {
      list-style: none;
      display: flex;
      align-items: center;
      padding: rem(14px) rem(12px);
      background: #fff;
      border-radius: 12px;
      @media screen and (max-width: 1199px) {
        margin-bottom: rem(20px) !important;
      }
      .icon {
        height: 39px;
        width: 39px;
        min-width: 39px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      li {
        font-size: 11px;
        color: #8998ac;
        p {
          margin: 0;
          font-size: rem(18px);
          font-weight: 500;
          color: #3b485a;
        }
      }
    }
    &--hashtags {
      margin: rem(12px) 0 rem(8px) 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 1199px) {
        margin-top: 0;
      }
      li {
        margin-right: 6px;
        margin-bottom: 6px;
        a {
          font-size: rem(18px);
          font-weight: 500;
          color: var(--textPrimary);
          transition: 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    &--btnWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: rem(13px);
      h2 {
        margin: 0;
      }
      @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: rem(24px);
        h2 {
          margin-bottom: rem(16px);
        }
      }
    }
    p {
      font-size: rem(14px);
      font-family: $secondary-font;
      margin-bottom: rem(28px);
    }
    h2 {
      font-size: rem(27px);
    }
    .inline-btns {
      display: flex;
      .btn.btn-primary {
        min-height: 40px;
        width: 120px;
        padding: 10px;
        font-size: rem(14px);
        border-radius: 6px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    &--icon {
      height: 50px;
      width: 50px;
      min-width: 50px;
      background: #1997cc;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 11px;
      overflow: hidden;
    }
    span {
      font-size: rem(14px);
      font-weight: 600;
      color: var(--primary);
    }
  }
  .onDesktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .onMobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block !important;
    }
    ul {
      list-style: none;
      display: flex;
      align-items: center;
    }
  }
}
</style>
